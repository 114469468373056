import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Slider, TitleHeading } from '../layouts/home/index';
import {
	// eslint-disable-next-line
	Accordion,
	IconBox,
	Progress,
	Request,
	SliderService,
	TabProject,
	// eslint-disable-next-line
	Partner,
} from '../layouts/home/index';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';
import Testimonial from '../layouts/home/Testimonial';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [
				{
					id: 1,
					logoweb: 'assets/img/logo-small.png',
					names: 'Home',
				},
			],
			ourservice: [
				{
					id: 1,
					classnames: 'heading',
					title: 'OUR SERVICES',
				},
			],
			leading: [
				{
					id: 1,
					classnames: 'heading text-white',
					title: 'LEADING CONSTRUCTION',
				},
			],
			choose: [
				{
					id: 1,
					classnames: 'heading',
					title: 'WHY CHOOSE US?',
				},
			],
		};
	}
	render() {
		return (
			<div className='header-fixed page no-sidebar header-style-3 topbar-style-3 site-header-absolute menu-has-search'>
				<div id='wrapper'>
					<div id='page'>
						<div id='site-header-wrap'>
							<TopBar />
							{this.state.headers.map((data, idx) => (
								<Header data={data} key={idx} />
							))}
						</div>
						<div id='main-content' className='site-main clearfix'>
							<div id='content-wrap'>
								<div id='site-content' className='site-content clearfix'>
									<div id='inner-content' className='inner-content-wrap'>
										<div className='page-content'>
											<div className='rev_slider_wrapper fullwidthbanner-container'>
												<div id='rev-slider2' className='rev_slider fullwidthabanner'>
													<Slider />
												</div>
											</div>

											<div className='row-about'>
												<div className='container-fluid'>
													<div className='row equalize sm-equalize-auto'>
														<div className='col-md-6 half-background style-1' />
														<div className='col-md-6 bg-light-grey'>
															<div className='themesflat-spacer clearfix' data-desktop={64} data-mobile={60} data-smobile={60} />
															<div className='themesflat-content-box' data-margin='0 25% 0 4.5%' data-mobilemargin='0 0 0 4.5%'>
																<div className='themesflat-headings style-1'>
																	<h2 className='heading'>WELCOME TO BMR BUILDING LONDON LTD</h2>
																	<div className='accent-bg' />
																	<div className='sub-heading margin-top-30'>
																		<p>
																			Our mission is to fullfil our clients' needs and to deliver high quality work on time and on budget.
																		</p>
																		<p>
																			We have successfully completed projects all around England, including: extensions, loft conversions,
																			kitchens, bathrooms, refurbished houses, electrical, partitions, ceilings, painting, tiling,
																			decorating, double glazing, gas safe boiler installations, flooring and more.
																		</p>
																		<p>
																			Our quotes are competitive and we ensure our work is completed to the highest standards. We pride
																			ourselves in delivering high quality work. Our team of professionals are fully qualified in the
																			trade.
																		</p>
																	</div>
																</div>

																<div className='content-list'>
																	<div className='themesflat-list has-icon style-1 icon-left clearfix'>
																		<div className='inner'>
																			{/* {this.state.inner.map((data) => (
																				<span className='item' key={data.id}>
																					<span className='icon'>
																						<i className={data.icon} />
																					</span>
																					<span className='text'>{data.text}</span>
																				</span>
																			))} */}
																		</div>
																	</div>
																</div>

																<div className='elm-button mb-5'>
																	<Link
																		to='/contacts'
																		className='themesflat-button bg-white'
																		onClick={() => {
																			window.location.href = '/contacts';
																		}}
																	>
																		GET IN TOUCH
																	</Link>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className='row-services'>
												<div className='container'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={60} data-mobile={60} data-smobile={60} />
															{this.state.ourservice.map((data) => (
																<TitleHeading data={data} key={data.id} />
															))}
															<div
																className='themesflat-spacer clearfix'
																data-desktop='27'
																data-mobile='35'
																data-smobile='35'
															></div>
															<SliderService />
															<div className='themesflat-spacer clearfix' data-desktop={45} data-mobile={60} data-smobile={60} />
														</div>
													</div>
												</div>
											</div>
											<div className='row-iconbox bg-row-2'>
												<div className='container'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={60} data-mobile={60} data-smobile={60} />
															{this.state.leading.map((data) => (
																<TitleHeading data={data} key={data.id} />
															))}
															<div className='themesflat-spacer clearfix' data-desktop={25} data-mobile={35} data-smobile={35} />
															<IconBox />
															<div className='row'>
																<div className='col-md-12'>
																	<div className='themesflat-spacer clearfix' data-desktop={80} data-mobile={60} data-smobile={60} />
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
											{/* <div className='row-whyus'>
												<div className='container'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={61} data-mobile={60} data-smobile={60} />
															{this.state.choose.map((data) => (
																<TitleHeading data={data} key={data.id} />
															))}
															<div className='themesflat-spacer clearfix' data-desktop={29} data-mobile={35} data-smobile={35} />
														</div>
													</div>
													<div className='row'>
														<Progress />
														<Accordion />
													</div>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={76} data-mobile={60} data-smobile={60} />
														</div>
													</div>
												</div>
											</div> */}
											<Request />
											<div className='row-project clearfix'>
												<div className='container'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={60} data-mobile={60} data-smobile={60} />
															<div className='position-relative'>
																<div className='themesflat-headings style-1 clearfix'>
																	<h2 className='heading '>RECENT PROJECTS</h2>
																</div>
																<ul className='themesflat-filter style-1 filter-absolute clearfix'>
																	<li className='active'>
																		<Link to='#' data-filter='*'>
																			All
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.kitchens'>
																			KITCHENS
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.bathrooms'>
																			BATHROOMS{' '}
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.flooring'>
																			FLOORING{' '}
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.tiling'>
																			TILING
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.stairs'>
																			STAIRS
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.roofing'>
																			ROOFING
																		</Link>
																	</li>
																	<li>
																		<Link to='#' data-filter='.electric'>
																			ELECTRICAL
																		</Link>
																	</li>
																</ul>
															</div>
															<div className='themesflat-spacer clearfix' data-desktop={40} data-mobile={35} data-smobile={35} />
															<TabProject />
															{/* <div className='button-wrap has-icon icon-left size-14 pf21 text-center'>
																<Link
																	to='/projects'
																	className='themesflat-button bg-accent pd32'
																	onClick={() => {
																		window.location.href = '/projects';
																	}}
																>
																	<span>
																		VIEW ALL{' '}
																		<span className='icon'>
																			<i className='ion-load-c' />
																		</span>
																	</span>
																</Link>
															</div> */}
														</div>
													</div>
												</div>
											</div>
											{/* <Partner /> */}
											<Testimonial />
										</div>
									</div>
								</div>
							</div>
						</div>
						<Footer />
						{this.state.headers.map((data, idx) => (
							<BottomBar data={data} key={idx} />
						))}
					</div>
				</div>
			</div>
		);
	}
}

export default Home;
