import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import GetReviews from './GetReviews';

class Testimonial extends Component {
	render() {
		return (
			<div className='row-testimonials bg-light-snow'>
				<div className='container'>
					<div className='row'>
						<div className='col-md-12'>
							<div className='themesflat-spacer clearfix' data-desktop={60} data-mobile={60} data-smobile={60} />
							<div className='themesflat-headings style-1 font-hind text-center clearfix'>
								<h2 className='heading'>CUSTOMER COMMENTS</h2>
								<div className='sep has-icon width-125 clearfix'>
									<div className='sep-icon'>
										<span className='sep-icon-before sep-center sep-solid' />
										<span className='icon-wrap'>
											<i className='autora-icon-build' />
										</span>
										<span className='sep-icon-after sep-center sep-solid' />
									</div>
								</div>
								<p className='sub-heading'>
									With us you will have the peace of mind knowing that your dream home project is in the hands <br />{' '}
									of a fully qualified team.
								</p>
							</div>
							<div className='themesflat-spacer clearfix' data-desktop={46} data-mobile={35} data-smobile={35} />
							<GetReviews />
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default Testimonial;
