import Home from './Home';
import AboutUs from './AboutUs';
import Services from './Services';
import Projects from './Projects';
import Testimonial from './Testimonial';
import Contact from './Contact';

const routes = [
	{ path: '/', component: Home },
	{ path: '/about-us', component: AboutUs },
	{ path: '/services', component: Services },
	{ path: '/projects', component: Projects },
	{ path: '/reviews', component: Testimonial },
	{ path: '/contacts', component: Contact },
];

export default routes;
