const bannerhome = [	{
		id: 1,
		srcimg: 'assets/img/slider/slider-bg-1.jpg',
		classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600',
		classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700',
		datax: "['left','left','left','center']",
		datahset: "['0','0','0','0']",
		datavset1: "['-90','-90','-90','-90']",
		datavset2: "['-20','-20','-20','-20']",
		datavset3: "['45','45','45','45']",
		datavset: "['153','153','153','153']",
		classbtn: 'tp-caption',
		subtitle: 'WITH 27 YEARS EXPERIENCE',
		title: 'WE WILL BE HAPPY,',
		title2: 'TO TAKE CARE OF YOUR WORK!',
		button: 'GET IN TOUCH',
	},
	{
		id: 2,
		srcimg: 'assets/img/slider/slider-bg-2.jpg',
		classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-center',
		classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-center',
		datax: "['center','center','center','center']",
		datahset: "['0','0','0','0']",
		datavset1: "['-90','-90','-90','-90']",
		datavset2: "['-20','-20','-20','-20']",
		datavset3: "['45','45','45','45']",
		datavset: "['153','153','153','153']",
		classbtn: 'tp-caption text-center',
		subtitle: 'Our experts are',
		title: 'Fully qualified,',
		title2: 'All work is done to a high standard!',
		button: 'GET IN TOUCH',
	},
	{
		id: 3,
		srcimg: 'assets/img/slider/slider-bg-3.jpg',
		classnames: 'tp-caption tp-resizeme text-white font-heading font-weight-600 text-right',
		classtext: 'tp-caption tp-resizeme text-white font-heading font-weight-700 text-right',
		datax: "['right','right','right','center']",
		datahset: "['0','0','0','0']",
		datavset1: "['-90','-90','-90','-90']",
		datavset2: "['-20','-20','-20','-20']",
		datavset3: "['45','45','45','45']",
		datavset: "['153','153','153','153']",
		classbtn: 'tp-caption text-right',
		subtitle: 'EXTENSIONS | BATHROOMS | KITCHENS',
		title: 'CONSTRUCTION INDUSTRY,',
		title2: 'WE GET YOUR JOB DONE!',
		button: 'GET IN TOUCH',
	},
];

export default bannerhome;
