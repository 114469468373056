import React, { useState, useEffect, useRef } from 'react';import * as api from '../../../api';
import reCAPTCHA from 'react-google-recaptcha';

import { FaStar } from 'react-icons/fa';
import { Rating } from './RatingStyles';
const colors = {
	orange: '#e42320',
	grey: '#a9a9a9',
};

function Ratings() {
	const captchaRef = useRef(null);
	const stars = Array(5).fill(0);
	const [currentValue, setCurrentValue] = React.useState(0);
	const [hoverValue, setHoverValue] = React.useState(undefined);

	const handleClick = (value) => {
		setCurrentValue(value);
	};

	const handleMouseOver = (value) => {
		setHoverValue(value);
	};

	const [review, setReview] = useState({});
	const [reviews, setReviews] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const result = await api.readReviews();
			setReviews(result.data.data);
		};
		fetchData();
	}, []);

	const createReview = async (event) => {
		event.preventDefault();
		try {
			const data = await api.createReview({ ...review });
			setReviews([...reviews, data]);
			window.location.reload();
		} catch (error) {
			console.log(error);
		}
	};
	let [reviewCount, setreviewCount] = useState([]);
	const setCountFxn = (no) => {
		setReview(no);
		console.log(no);
	};

	return (
		<div className='col-md-12 row-testimonials'>
			<div className='themesflat-spacer clearfix' data-desktop={46} data-mobile={35} data-smobile={35} />
			<div className='themesflat-headings style-1 font-hind text-center clearfix'>
				<h2 className='heading'>CUSTOMER COMMENTS</h2>
				<div className='sep has-icon width-125 clearfix'>
					<div className='sep-icon'>
						<span className='sep-icon-before sep-center sep-solid' />
						<span className='icon-wrap'>
							<i className='autora-icon-build' />
						</span>
						<span className='sep-icon-after sep-center sep-solid' />
					</div>
				</div>
				<p className='sub-heading'>
					With us you will have the peace of mind knowing that your dream home project is in the hands <br />{' '}
					of a fully qualified team.
				</p>
			</div>

			<div
				className='themesflat-carousel-box has-bullets bullet-circle has-buttons has-arrows clearfix'
				data-gap={30}
				data-column={3}
				data-column2={2}
				data-column3={1}
				data-auto='false'>
				<div className='owl-carousel owl-theme'>
					{reviews.map((review) => (
						<div className='themesflat-testimonials style-2 align-center clearfix' key={review.id}>
							<div className='testimonial-item'>
								<div className='inner'>
									<div className='thumb'>
										<img src='assets/img/testimonials/customer-1-90x90.jpg' alt='altimage' />
									</div>
									<blockquote className='text'>
										<div className='name-pos clearfix'>
											<h6 className='name'>{review.Title}</h6>
											<span className='position'></span>
										</div>
										<p>{review.Body}</p>
										<div className='list-star'>
											{Array.from({ length: review.Rating }).map((i) => (
												<FaStar key={i} size={18} color={colors.orange} />
											))}
										</div>
										<div className='m-2'>
											By: <span className='name'>{review.Name}</span>
										</div>
									</blockquote>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<div className='themesflat-spacer clearfix' data-desktop={60} data-mobile={60} data-smobile={60} />
			<div className='bg-black'>
				<form>
					<div className='mb-5'>
						<div className='themesflat-headings style-1 font-hind text-center clearfix'>
							<h2 className='heading'>RATE OUR SERVICE</h2>
							<div className='sep has-icon width-125 clearfix'>
								<div className='sep-icon'>
									<span className='sep-icon-before sep-center sep-solid' />
									<span className='icon-wrap'>
										<i className='autora-icon-build' />
									</span>
									<span className='sep-icon-after sep-center sep-solid' />
								</div>
							</div>
						</div>
						<div className='mt-5 mb-5'>
							{stars.map((_, index) => {
								return (
									<FaStar
										key={index}
										size={20}
										style={{
											marginRight: 10,
											cursor: 'pointer',
										}}
										color={(hoverValue || currentValue) > index ? colors.orange : colors.grey}
										onClick={() => {
											setReview({ ...review, Rating: index + 1 });
										}}
										onMouseOver={() => handleMouseOver(index + 1)}
									/>
								);
							})}
						</div>

						<div id='message'></div>
						<div>
							<input
								type='text'
								placeholder='Your Name'
								required
								value={review.Name}
								onChange={(e) => setReview({ ...review, Name: e.target.value })}
								className='wpcf7-form-control'
							/>
						</div>
						<div>
							<input
								type='text'
								placeholder='Review Title'
								required
								value={review.Title}
								onChange={(e) => setReview({ ...review, Title: e.target.value })}
								className='wpcf7-form-control'
							/>
						</div>

						<textarea
							placeholder='Your comment'
							required
							value={review.Body}
							onChange={(e) => setReview({ ...review, Body: e.target.value })}
							className='wpcf7-form-control'
						/>
						<reCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} ref={captchaRef} />
						<button type='submit' className='submit wpcf7-form-control wpcf7-submit' onClick={createReview}>
							submit
						</button>
					</div>
				</form>
			</div>
			<div className='themesflat-spacer clearfix' data-desktop={80} data-mobile={60} data-smobile={60} />
		</div>
	);
}

export default Ratings;
