import React, { Component } from 'react';
import { Link } from 'react-router-dom';
class Footer extends Component {
	render() {
		return (
			<footer id='footer' className='clearfix'>
				<div id='footer-widgets' className='container'>
					<div className='themesflat-row gutter-30'>
						<div className='col span_1_of_3'>
							<div className='widget widget_text'>
								<div className='textwidget'>
									<p>
										<img src='assets/img/logo-white.png' alt='imagealt' width={170} height={34} />
									</p>

									<ul>
										<li>
											<div className='inner'>
												<span className='fa fa-map-marker' />
												<span className='text-white'>London</span>
											</div>
										</li>
										<li>
											<div className='inner'>
												<span className='fa fa-phone' />
												<span className='text-white'>
													<a href='tel:07446842173' className='text-white'>
														CALL US : 07446 842173
													</a>
												</span>
											</div>
										</li>
										<li className='margin-top-7'>
											<div className='inner'>
												<span className='font-size-14 fa fa-envelope' />
												<span>
													<a href='mailto:INFO@BMRBUILDING.CO.UK' className='text-white'>
														INFO@BMRBUILDING.CO.UK
													</a>
												</span>
											</div>
										</li>
									</ul>
								</div>
							</div>

							<div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={0} data-smobile={35} />
						</div>
						{/* /.col */}
						<div className='col span_1_of_3'>
							<div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={0} data-smobile={0} />
							<div className='widget widget_lastest'>
								<h2 className='widget-title'>
									<span>MAIN MENU</span>
								</h2>
								<ul className='lastest-posts data-effect clearfix'>
									<li>
										<Link
											to='/'
											onClick={() => {
												window.location.href = '/';
											}}
											className='text-white'
										>
											Home
										</Link>
									</li>
									{/* <li>
										<Link
											to='/services'
											onClick={() => {
												window.location.href = '/services';
											}}
											className='text-white'
										>
											Services
										</Link>
									</li>
									<li>
										<Link
											to='/projects'
											onClick={() => {
												window.location.href = '/projects';
											}}
											className='text-white'
										>
											Projects
										</Link>
									</li> */}
									{/* <li>
									<Link
										to='/about'
										onClick={() => {
											window.location.href = '/about-us';
										}}
									>
										About Us
									</Link>
								</li> */}
									<li>
										<Link
											to='/contacts'
											onClick={() => {
												window.location.href = '/contacts';
											}}
											className='text-white'
										>
											Contact Us
										</Link>
									</li>
								</ul>
							</div>
							{/* /.widget_lastest */}
						</div>
						{/* /.col */}

						{/* /.col */}
						<div className='col span_1_of_3'>
							<div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={35} data-smobile={35} />
							<div className='widget widget_tags'>
								<h2 className='widget-title margin-bottom-30'>
									<span>OUR SERVICES</span>
								</h2>
								<div className='tags-list'>
									<Link to='#'>Building</Link>
									<Link to='#'>Bathrooms</Link>
									<Link to='#'>Kitchens</Link>
									<Link to='#'>Flooring</Link>
									<Link to='#'>Tiling</Link>
									<Link to='#'>Electrical</Link>
									<Link to='#'>Loft Conversions & Extensions</Link>
									<Link to='#'>AND MUCH MORE...</Link>
								</div>
							</div>
						</div>
						{/* /.col */}
						<div className='col span_1_of_3'>
							<div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={35} data-smobile={35} />
							<div className='widget widget_instagram'>
								<h2 className='widget-title margin-bottom-30'>
									<span>OUR LOCATION</span>
								</h2>
								{/* <div className='instagram-wrap data-effect clearfix col3 g10'>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-1-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-2-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-3-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-4-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-5-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
									<div className='instagram_badge_image has-effect-icon'>
										<Link to='#' target='_blank' className='data-effect-item'>
											<span className='item'>
												<img src='assets/img/instagram/instagram-6-83x83.jpg' alt='imagealt' />
											</span>
											<div className='overlay-effect bg-color-2' />
											<div className='elm-link'>
												<span className='icon-3' />
											</div>
										</Link>
									</div>
								</div> */}

								<iframe
									src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.3388344632535!2d-0.25546878402624745!3d51.580353113187236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876113dd44c6301%3A0xe40da5926877bfe6!2sFryent%20Cres%2C%20London%20NW9%207HH%2C%20UK!5e0!3m2!1sen!2s!4v1667897873548!5m2!1sen!2s'
									height={'200'}
									width={'100%'}
								></iframe>
							</div>
						</div>
					</div>
				</div>
			</footer>
		);
	}
}

export default Footer;
