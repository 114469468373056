import React, { Component } from 'react';
class Information extends Component {
	render() {
		return (
			<div className='col-md-6'>
				<div className='themesflat-spacer clearfix' data-desktop={0} data-mobile={0} data-smobile={35} />
				<div className='themesflat-headings style-2 clearfix'>
					<h2 className='heading'>INFORMATION</h2>
					<div className='sep has-width w80 accent-bg clearfix' />
				</div>
				<div className='themesflat-spacer clearfix' data-desktop={36} data-mobile={35} data-smobile={35} />
				<div className='themesflat-tabs style-1 w168 clearfix'>
					{/* <ul className="tab-title clearfix">
                        <li className="item-title active">
                            <span className="inner">Head Office</span>
                        </li>
                        <li className="item-title">
                            <span className="inner">Colombia</span>
                        </li>
                        <li className="item-title">
                            <span className="inner">Mexico</span>
                        </li>
                    </ul> */}
					<div className='tab-content-wrap clearfix'>
						<div className='tab-content'>
							<div className='item-content'>
								<ul>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-map-marker' />
											<span className='text'>London</span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-phone' />
											<span className='text'>
												<a href='tel:07446842173' className='text'>
													CALL US : 07446 842173
												</a>
											</span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-envelope' />
											<span className='text'>EMAIL : INFO@BMRBUILDING.CO.UK</span>
										</div>
									</li>
								</ul>
							</div>
						</div>
						{/* <div className='tab-content'>
							<div className='item-content'>
								<ul>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-map-marker' />
											<span className='text'>PO BOX 16122 COLLINS STREET WEST VICTORIA, NEWYORK </span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-phone' />
											<span className='text'>CALL US : (+61) 3 8376 6284</span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-envelope' />
											<span className='text'>EMAIL : SUPPORT@THEMESFLAT.COM</span>
										</div>
									</li>
								</ul>
							</div>
						</div> */}
						{/* <div className='tab-content'>
							<div className='item-content'>
								<ul>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-map-marker' />
											<span className='text'>PO BOX 16122 COLLINS STREET WEST VICTORIA, NEWYORK </span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-phone' />
											<span className='text'>CALL US : (+61) 3 8376 6284</span>
										</div>
									</li>
									<li className='clearfix'>
										<div className='inner'>
											<span className='fa fa-envelope' />
											<span className='text'>EMAIL : SUPPORT@THEMESFLAT.COM</span>
										</div>
									</li>
								</ul>
							</div>
						</div> */}
					</div>
				</div>
				<div className='themesflat-spacer clearfix' data-desktop={20} data-mobile={35} data-smobile={35} />
				{/* <div className='themesflat-map' /> */}
				<iframe className='tf-map' src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2479.3388344632535!2d-0.25546878402624745!3d51.580353113187236!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4876113dd44c6301%3A0xe40da5926877bfe6!2sFryent%20Cres%2C%20London%20NW9%207HH%2C%20UK!5e0!3m2!1sen!2s!4v1667897873548!5m2!1sen!2s"></iframe>
			</div>
		);
	}
}

export default Information;
