import React, { Component } from 'react';import { Link } from 'react-router-dom';

class TabProject extends Component {
	constructor(props) {
		super(props);
		this.state = {
			limit: 6,
			projects: [
				{
					id: 1,
					classnames: 'project-item kitchens',
					srcimg: 'assets/img/project/project-1-370x245.jpg',
					srclarge: 'assets/img/project/project-1-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 2,
					classnames: 'project-item kitchens',
					srcimg: 'assets/img/project/project-2-370x245.jpg',
					srclarge: 'assets/img/project/project-2-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 3,
					classnames: 'project-item kitchens',
					srcimg: 'assets/img/project/project-3-370x245.jpg',
					srclarge: 'assets/img/project/project-3-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 4,
					classnames: 'project-item bathrooms',
					srcimg: 'assets/img/project/project-4-370x245.jpg',
					srclarge: 'assets/img/project/project-4-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 5,
					classnames: 'project-item bathrooms',
					srcimg: 'assets/img/project/project-5-370x245.jpg',
					srclarge: 'assets/img/project/project-5-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 6,
					classnames: 'project-item bathrooms',
					srcimg: 'assets/img/project/project-6-370x245.jpg',
					srclarge: 'assets/img/project/project-6-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 7,
					classnames: 'project-item flooring',
					srcimg: 'assets/img/project/project-7-370x245.jpg',
					srclarge: 'assets/img/project/project-7-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 8,
					classnames: 'project-item flooring',
					srcimg: 'assets/img/project/project-8-370x245.jpg',
					srclarge: 'assets/img/project/project-8-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 9,
					classnames: 'project-item flooring',
					srcimg: 'assets/img/project/project-9-370x245.jpg',
					srclarge: 'assets/img/project/project-9-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 10,
					classnames: 'project-item tiling',
					srcimg: 'assets/img/project/project-10-370x245.jpg',
					srclarge: 'assets/img/project/project-10-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 11,
					classnames: 'project-item tiling',
					srcimg: 'assets/img/project/project-11-370x245.jpg',
					srclarge: 'assets/img/project/project-11-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 12,
					classnames: 'project-item tiling',
					srcimg: 'assets/img/project/project-12-370x245.jpg',
					srclarge: 'assets/img/project/project-12-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 13,
					classnames: 'project-item tiling',
					srcimg: 'assets/img/project/project-13-370x245.jpg',
					srclarge: 'assets/img/project/project-13-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 14,
					classnames: 'project-item stairs',
					srcimg: 'assets/img/project/project-14-370x245.jpg',
					srclarge: 'assets/img/project/project-14-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 15,
					classnames: 'project-item stairs',
					srcimg: 'assets/img/project/project-15-370x245.jpg',
					srclarge: 'assets/img/project/project-15-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 16,
					classnames: 'project-item stairs',
					srcimg: 'assets/img/project/project-16-370x245.jpg',
					srclarge: 'assets/img/project/project-16-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 17,
					classnames: 'project-item roofing',
					srcimg: 'assets/img/project/project-17-370x245.jpg',
					srclarge: 'assets/img/project/project-17-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 18,
					classnames: 'project-item roofing',
					srcimg: 'assets/img/project/project-18-370x245.jpg',
					srclarge: 'assets/img/project/project-18-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 19,
					classnames: 'project-item roofing',
					srcimg: 'assets/img/project/project-19-370x245.jpg',
					srclarge: 'assets/img/project/project-19-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 20,
					classnames: 'project-item electric',
					srcimg: 'assets/img/project/project-20-370x245.jpg',
					srclarge: 'assets/img/project/project-20-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
				{
					id: 21,
					classnames: 'project-item electric',
					srcimg: 'assets/img/project/project-21-370x245.jpg',
					srclarge: 'assets/img/project/project-21-880x560.jpg',
					heading: '',
					name01: '',
					name02: '',
				},
			],
		};
	}

	render() {
		return (
			<div className='themesflat-project style-2 isotope-project has-margin mg15 data-effect clearfix'>
				{this.state.projects.map((data) => (
					<div className={data.classnames} key={data.id}>
						<div className='inner'>
							<div className='thumb data-effect-item has-effect-icon w40 offset-v-19 offset-h-49'>
								<Link to={data.srclarge} className='popup-gallery'>
									<img src={data.srcimg} alt='altimage' />
									<div className='elm-link text-center'>
										<Link to={data.srclarge} className='icon-1 icon-search popup-gallery ml-5' />
									</div>
									<div className='overlay-effect bg-color-3' />
								</Link>
							</div>
							{/* <div className='text-wrap'>
								<h5 className='heading'>
									<Link to='#'>{data.heading}</Link>
								</h5>
								<div className='elm-meta'>
									<span>
										<Link to='#'>{data.name01}</Link>
									</span>
									<span>
										<Link to='#'>{data.name01}</Link>
									</span>
								</div>
							</div> */}
						</div>
					</div>
				))}
			</div>
		);
	}
}

export default TabProject;
