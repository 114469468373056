import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import * as api from '../../../api';

import { FaStar } from 'react-icons/fa';
const colors = {
	orange: '#e42320',
	grey: '#a9a9a9',
};

function Ratings() {
	const [reviews, setReviews] = useState([]);
	useEffect(() => {
		const fetchData = async () => {
			const result = await api.readReviews();
			setReviews(result.data.data);
		};
		fetchData();
	}, []);

	return (
		<div className='col-md-12 row-testimonials'>
			<div
				className='themesflat-carousel-box has-bullets bullet-circle has-buttons has-arrows clearfix'
				data-gap={30}
				data-column={3}
				data-column2={2}
				data-column3={1}
				data-auto='false'>
				<div className='owl-carousel owl-theme'>
					{reviews.map((review) => (
						<div className='themesflat-testimonials style-2 align-center clearfix' key={review.id}>
							<div className='testimonial-item'>
								<div className='inner'>
									<div className='thumb'>
										<img src='assets/img/testimonials/customer-1-90x90.jpg' alt='altimage' />
									</div>
									<blockquote className='text'>
										<div className='name-pos clearfix'>
											<h6 className='name'>{review.Title}</h6>
											<span className='position'></span>
										</div>
										<p>{review.Body}</p>
										<div className='list-star'>
											{Array.from({ length: review.Rating }).map((i) => (
												<FaStar key={i} size={18} color={colors.orange} />
											))}
										</div>
										<div className='m-2'>
											By: <span className='name'>{review.Name}</span>
										</div>
									</blockquote>
								</div>
							</div>
						</div>
					))}
				</div>
			</div>
			<p className='text-right'>
				<Link
					to='/reviews'
					className='themesflat-button bg-accent big'
					onClick={() => {
						window.location.href = '/reviews';
					}}>
					<span>View All Reviews</span>
				</Link>
			</p>
			<div className='themesflat-spacer clearfix' data-desktop={80} data-mobile={60} data-smobile={60} />
		</div>
	);
}

export default Ratings;
