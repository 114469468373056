import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

class BottomBar extends Component {
	constructor(props) {
		super(props);
		this.state = {
			menus: [
				{
					id: 1,
					name: 'Home',
					links: '/',
				},
				{
					id: 2,
					name: 'About Us',
					links: '/about-us',
				},
				{
					id: 3,
					name: 'Services',
					links: '/services',
				},
				{
					id: 4,
					name: 'Projects',
					links: '/project',
				},
				{
					id: 5,
					name: 'Page',
					links: '/page-testimonial',
				},
				{
					id: 6,
					name: 'Blog',
					links: '/blog',
				},
				{
					id: 7,
					name: 'Contact',
					links: '/contact-01',
				},
			],
		};
	}

	render() {
		return (
			<div id='bottom' className='clearfix has-spacer'>
				<div id='bottom-bar-inner' className='container'>
					<div className='bottom-bar-inner-wrap'>
						<div className='bottom-bar-content'>
							<div id='copyright'>
								&copy; <span className='text'>2022 BMR Building London Ltd All Rights Reserved</span>
							</div>
						</div>
						{/* /.bottom-bar-content */}
					</div>
				</div>
			</div>
		);
	}
}

export default withRouter(BottomBar);
