import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import Testimonial from '../home/Testimonial';

class Header extends Component {
	render() {
		return (
			<header id='site-header'>
				<div id='site-header-inner' className='container'>
					<div className='wrap-inner clearfix'>
						<div id='site-logo' className='clearfix'>
							<div id='site-log-inner'>
								<Link
									to='/'
									onClick={() => {
										window.location.href = '/';
									}}
									rel='home'
									className='main-logo'
								>
									<img
										src={this.props.data.logoweb}
										alt='BMR Building London Ltd'
										width={186}
										height={39}
										data-retina='assets/img/logo-small@2x.png'
										data-width={177}
										data-height={34}
									/>
								</Link>
							</div>
						</div>
						<div className='mobile-button'>
							<span />
						</div>
						<nav id='main-nav' className='main-nav'>
							<ul id='menu-primary-menu' className='menu'>
								<li>
									<Link
										to='/'
										onClick={() => {
											window.location.href = '/';
										}}
									>
										Home
									</Link>
								</li>
								{/* <li>
									<Link
										to='/services'
										onClick={() => {
											window.location.href = '/services';
										}}
									>
										Services
									</Link>
								</li>
								<li>
									<Link
										to='/projects'
										onClick={() => {
											window.location.href = '/projects';
										}}
									>
										Projects
									</Link>
								</li> */}
								{/* <li>
									<Link
										to='/about'
										onClick={() => {
											window.location.href = '/about-us';
										}}
									>
										About Us
									</Link>
								</li> */}
								<li>
									<Link
										to='/contacts'
										onClick={() => {
											window.location.href = '/contacts';
										}}
									>
										Contact Us
									</Link>
								</li>
								<li>
									<Link
										to='/reviews'
										onClick={() => {
											window.location.href = '/reviews';
										}}
									>
										Testimonials
									</Link>
								</li>
							</ul>
						</nav>
					</div>
				</div>
			</header>
		);
	}
}

export default withRouter(Header);
