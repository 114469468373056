import React, { Component } from 'react';import { Link } from 'react-router-dom';

class TopBar extends Component {
	render() {
		return (
			<div id='top-bar'>
				<div id='top-bar-inner' className='container'>
					<div className='top-bar-inner-wrap'>
						<div className='top-bar-content'>
							<div className='inner'>
								{/* <span className='address content'></span> */}
								<span className='phone content'>
									<a href='tel:07446842173'>07446 842173</a>
								</span>
								<span className='time content'>
									<Link
										to='/contacts'
										onClick={() => {
											window.location.href = '/contacts';
										}}
									>
										Get In Touch
									</Link>
								</span>
							</div>
						</div>
						{/* <div className="top-bar-socials">
                            <div className="inner">
                                <span className="text">Follow us:</span>
                                <span className="icons">
                                    <Link to="#"><i className="fa fa-facebook" /></Link>
                                    <Link to="#"><i className="fa fa-twitter" /></Link>
                                    <Link to="#"><i className="fa fa-pinterest-p" /></Link>
                                    <Link to="#"><i className="fa fa-rss" /></Link>
                                </span>
                            </div>
                        </div> */}
					</div>
				</div>
			</div>
		);
	}
}

export default TopBar;
