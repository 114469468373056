import React, { Component } from 'react';
import { FormContact, Information } from '../layouts/contact';
import { Header, Footer, TopBar, BottomBar } from '../layouts/general/index';

class Contact extends Component {
	constructor(props) {
		super(props);
		this.state = {
			headers: [
				{
					id: 1,
					logoweb: 'assets/img/logo-small.png',
					names: 'Contact',
				},
			],
			banners: [
				{
					id: 1,
					links: '',
					titlelink: '',
					border: '',
					name: 'Contact',
					heading: 'Contact Us',
				},
			],
		};
	}
	render() {
		return (
			<div className='header-fixed page no-sidebar header-style-2'>
				<div id='wrapper'>
					<div id='page' className='clearfix'>
						<div id='site-header-wrap'>
							<TopBar />
							{this.state.headers.map((data, idx) => (
								<Header data={data} key={idx} />
							))}
						</div>

						<div id='main-content' className='site-main clearfix'>
							<div id='content-wrap'>
								<div id='site-content' className='site-content clearfix'>
									<div id='inner-content' className='inner-content-wrap'>
										<div className='page-content'>
											<div className='row-contact'>
												<div className='container'>
													<div className='row'>
														<div className='col-md-12'>
															<div className='themesflat-spacer clearfix' data-desktop={61} data-mobile={60} data-smobile={60} />
														</div>
													</div>
													<div className='row'>
														<FormContact />
														<Information />
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Footer />
					<BottomBar />
				</div>
			</div>
		);
	}
}

export default Contact;
